import React from "react"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import {Layout} from '@components/layout/layout'
import { H1, H3, Paragph } from "@components/layout/style"
import { ListDisc, MainContainer, Question,Response, RightContainer, StyledHero } from "../../components/oferta.style"

const StomatologiaZachowawcza:React.FC = ({data}) => {
  return (<Layout data={data} prop={{
    canonicalUrl: 'https://www.mediroot.pl/oferta/stomatologia-zachowawcza',
      title: 'Stomatologia zachowawcza | Mediroot',
      description: 'Mediroot ➤ Stomatolog Wągrowiec - Stomatologia zachowawcza ✔ Stomatologia dziecięca ✔ Ortodoncja ✔ Protetyka stomatologiczna ✔ Zapoznaj się z ofertą i zadzwoń już dziś! ☎'
  }}>
    <StyledHero>
      <StaticImage
        src="../../assets/images/hero.jpg"
        placeholder="blurred"
        alt="Hero image"
        layout="fullWidth"/>
    </StyledHero>
    <MainContainer>
      <RightContainer>
        <H1>
          STOMATOLOGIA ZACHOWAWCZA
        </H1>

        <Paragph>

        Dziedzina we współczesnej stomatologii, która poświęcona jest profilaktyce próchnicy zębów oraz leczeniu powstałych już ubytków próchniczych.
        Bardzo istotnym elementem jest profilaktyka, ważne jest, aby w przypadku stwierdzenia chorób zębów jak najszybciej rozpocząć leczenie zachowawcze. Zaniechanie leczenia, może spowodować zapalenie miazgi zęba, tym samym potrzebę leczenia kanałowego (endodontycznego).
        Leczenie  próchnicy polega na diagnostyce ubytku oraz jego opracowaniu i precyzyjnym wypełnieniu  dobranym materiałem . Wybierając odpowiednią tonację kolorystyczną możemy przywrócić idealnie utracone tkanki zęba. Stosujemy doskonałej jakości materiały kompozytowe do wypełnień. Prawidłowe i precyzyjne wypełnienie ubytku próchnicowego gwarantuje jego zabezpieczenie na wiele lat.
        </Paragph>

        <H3>
          Ważne informacje
        </H3>

        <Paragph>
          Próchnica zębów to długotrwały proces, który polega na demineralizacji i rozkładzie twardych tkanek zęba. Aby doszło do rozwoju próchnicy muszą jednocześnie zaistnieć cztery czynniki:
        </Paragph>
        <ListDisc>
          <li>
          bakterie
          </li>
          <li>
          cukry
          </li>
          <li>
          podatność zębów
          </li>
          <li>
          czas
          </li>
        </ListDisc>
        <Paragph>
        Każdy z wymienionych elementów jest czynnikiem koniecznym, ale samodzielnie nie wystarczającym do powstania stanu próchniczego, dlatego tylko przy zestawieniu tych elementów możemy mówić o warunkach do rozwoju próchnicy.
        </Paragph>
        <Paragph>
        Bakterie, które odpowiedzialne są za rozwój próchnicy to paciorkowce, które zdolne są do wytwarzania kwasów (kwas mlekowy) w wyniku metabolizowania cukrów. Zakwaszone środowisko, które wykazuje pH poniżej 5,5 sprzyja demineralizacji szkliwa i powstania próchnicy. Paciorkowce uczestniczą również w powstawaniu płytki nazębnej, która stanowi doskonałe środowisko dla rozwoju bakterii pierwotnie tlenowych, a z czasem względnie beztlenowych.
        </Paragph>
        <Paragph>
          Cukry stanowią podstawę metabolizmu bakterii, a jak wiadomo cukry sztucznie dodawane do pokarmów znajdują się w dużych ilościach w praktycznie każdym jedzeniu modyfikowanym chemicznie. Cukry te mają kolosalne znaczenie dla rozwoju próchnicy. Cukrem, który ma największy wpływ na rozwój próchnicy jest glukoza, sacharoza i fruktoza, natomiast skrobia ma znikomy wpływ na rozwój próchnicy.
          Substytuty cukru takie jak, aspartam nie wywołują próchnicy, a więc nie są niebezpieczne dla zdrowia jamy ustnej. Z badań wynika, że stężenie cukrów w ślinie jest bardzo zmienne i może się zmieniać w ciągu kilku minut nawet 100 000 razy, a więc czynnikiem zwiększającym rozwój próchnicy jest długotrwałe utrzymywanie się wysokiego stężenia cukrów w ślinie.
          Bakterie bytujące w płytce nazębnej reagują na zwiększone stężenie cukrów na trzy sposoby:

        </Paragph>
        <ListDisc>
          <li>
          przyspieszeniem metabolizmu
          </li>
          <li>
          skróceniem szlaków metabolicznych – metabolizm cukrów zostaje ograniczony tylko do glikolizy i wytworzenia kwasu mlekowego (co prowadzi do zakwaszenia płytki i demineralizacji twardych tkanek zęba)
          </li>
          <li>
          polimeryzacją cukrów i ich zmagazynowaniem poza komórką (tak powstaje lepki, nierozpuszczalny polimer glukozy mutan, który stanowi główny element spajający płytkę nazębną)
          </li>
        </ListDisc>
        <Paragph>
        Podatność zębów na próchnicę jest cechą indywidualną i nieznane są czynniki ryzyka, które wskazywałyby cechy ludzkie mające wpływ na tę przypadłość. Zapadalność na próchnicę jest różna u różnych osób i zdarza się zarówno wśród kobiet, mężczyzn, a także dzieci bez względu na wiek. Wynika to zarówno z predyspozycji genetycznych, jak i warunków socjalnych. Jednak z wielu przeprowadzonych badań oraz testów wynika, że dostarczanie wapnia i fluoru w okresie płodowym, jak też w okresie wzrostu ma niezwykle duży wpływ na późniejszą podatność zębów na próchnicę.
        </Paragph>
        <Paragph>
        Ostatnim czynnikiem, który prowadzi do powstania próchnicy jest czas, a więc w momencie kiedy trzy powyższe czynniki mają miejsce to, aby doszło do infekcji próchniczej to muszą one zaistnieć jednocześnie długo w czasie. Nawet wysokie spożycie cukru przy podatnych na próchnicę zębach, nie musi prowadzić do rozwoju tej choroby. Warunkiem jest jednak częste i dokładne oczyszczanie zębów z pozostałości pokarmowych.
        </Paragph>

        <H3>
          Pytania
        </H3>
        <Paragph style={{
          textIndent:0
        }}>
          <Question>
            Czy nieleczone zęby mogą być przyczyną choroby serca?
          </Question>
          <Response>
            Oczywiście, że tak. Szczególnie niebezpieczne są zmiany chorobowe w okolicy okołozębowej, np.: ropnie przyzębne, przewlekłe stany zapalne tkanek okołowierzchołkowych, choroby przyzębia. Gromadzące się tam bakterie i ich toksyny przedostają się do krwi, po czym mogą powodować m.in. zapalenie mięśnia sercowego, zapalenia wsierdzia, zastawek, a nawet zawał serca, czyli chwilowe niedotlenienie komórek mięśnia sercowego. Oprócz chorób serca, toksyny bakteryjne, pochodzące z nieleczonych zębów, bywają przyczyną chorób migdałków, infekcji gardła, uszu, zatok, sprzyjają wystąpieniu wrzodów żołądka, chorób stawów i nerek. W związku z tym lepiej regularnie kontrolować stan uzębienia przynajmniej co pół roku, niż czekać do momentu, kiedy ból zęba będzie zmuszał nas do wizyty u stomatologa. Pamiętajmy, że prawidłowa higiena jamy ustnej, regularna dieta i wizyty kontrolne spowodują, że dłużej będziemy cieszyć się zdrowiem i oczywiście pięknym uśmiechem.
          </Response>
        </Paragph>
      </RightContainer>
    </MainContainer>


  </Layout>);
};

export default StomatologiaZachowawcza;


export const query = graphql`
  query {
    logo: file(relativePath: { eq: "images/logo3.png" }) {
      childImageSharp {
        fluid( maxHeight: 92) {
          src
          sizes
          srcSet
          srcWebp
          base64
          aspectRatio
        }
      }
    }
  }
  `
